import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccommodationTypesStoreEffects } from './effects';
import { accommodationTypesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('accommodationTypes', accommodationTypesReducer),
    EffectsModule.forFeature([AccommodationTypesStoreEffects]),
  ],
})
export class AccommodationTypesStoreModule {}
